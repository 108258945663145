























































import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
    },
})
export default class BaseHeader extends Vue {
    @Prop() search!: string;
    @Prop({ default: "Title" }) title!: string;
    @Prop() buttonText!: string;
    @Prop({ default: "Tanggal" }) sortBy!: string;

    get searchValue() {
        return this.search;
    }

    set searchValue(search: string) {
        this.updateSearch(search);
    }

    @Emit("search:update")
    updateSearch(search: string) {
        return search;
    }

    @Emit("create")
    create() {
        return;
    }
}
